.ShareImage{
    display: none;
    flex-direction: column;
}

.ShareImage-content{
    display: flex;
    flex-direction: column;
    height: 240px;
    position: relative;
    background: #fff;
    padding: 24px;
    justify-content: center;
    /* height: 100vh; */
    background-image: url('../../assets/back3.svg');
    background-repeat: no-repeat; 
    background-position: left center;
    background-size: cover;
}


.ShareImage-heading{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    width:60%;
    position: absolute;
    top:40%;
}


.ShareImage-subheading{
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    padding: 8px 0;
    margin: 0px;
    color: rgba(0, 0, 0, 0.6);
    width: 60%;
    position: absolute;
    top:50%;
}

.ShareImage-img{
    position: absolute;
    top:40%;
    right: 0px;
    /* bottom: 0px; */
}