.App {
  /* text-align: center; */
  font-family: "Circular Std", sans-serif;
  background: #F7F7F7;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.help-btn{
    position: fixed;
    bottom:24px ;
    right:24px;
   
  background: #009AE0;
  border-radius: 120px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFF;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-btn img{
  width:24px;
  height: 24px;
  margin-right: 8px;
 
}

.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {opacity:0.7;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0.7;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0.7;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0.7;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0.7;}
  100% {opacity:1;}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
