.goalsModal{
    display: flex;
    flex-direction: column;
    /* margin: 0 0 120px 0; */

}

.goalsModal-note{
    background: #FFF6F1;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    margin: 16px ;
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.6);
}

.goalsModal-progressContainer{
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    margin-bottom: 24px;
    /* height: 30vh; */
    position: relative;
}

.goalsModal-progressRow{
    display: flex;
}

.goalsModal-progressRow-col1{
        display: flex;
        flex-direction: column;
        position: relative;
}
.goalsModal-progressRow-col1-img{
    width: 30px;
    height:30px;
}
.goalsModal-progressRow-col1-line{
    background: rgba(82, 176, 96, 0.8);
    border-radius: 120px;
    height: 50px;
    width: 4px;
    /* position: absolute; */
    margin-left:12px;
    margin-top:-8px;
    /* transform: rotate(90deg); */
}

.goalsModal-progressRow-col2{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */
    margin: 0px;
    padding-left: 16px;
    display: flex;
    align-items: flex-start;

    color: #3E9C4C;
}

.goalsModal-progressRow-col3{
    position: absolute;
    right: 0;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    margin: 0px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #3E9C4C;
}


.goalsModal-footer{
    background: #FFFFFF;
    border-top: 1px solid rgba(151, 151, 151, 0.247792);
    box-sizing: border-box;
    padding: 12px 16px;
}