.congrats{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.congrats-img{
    width:200px;
}

.congrats-content{
    display: flex;
    flex-direction: column;

}

.congrats-content-h1{
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 150%;
    /* identical to box height, or 39px */

    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px;
    color: #000000;
}

.congrats-content-h2{
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    margin: 0px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8px 16px 24px 16px;
    color: rgba(0, 0, 0, 0.6);

}

.congrats-content-notes{
    background: #FFF6F1;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin: 0px 16px 0 16px;
    width:90%;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.congrats-content-notes img{
    margin-right:8px
}

.congrats-content-img{

}

.congrats-footer{
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(151, 151, 151, 0.247792);
    box-sizing: border-box;
    padding: 12px;
    width: 100%;
}


@media(max-width:321px){
    .congrats-content-notes{
        font-size: 14px;
    }
}