.helpModal{
    display: flex;
   flex-direction: column;
}

.helpModal-content{
    margin: 16px;
    background: rgba(0, 154, 224, 0.04);
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 12px;
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;

    color: rgba(0, 0, 0, 0.6);


}
.helpModal-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-top: 1px solid rgba(151, 151, 151, 0.247792);
    box-sizing: border-box;
    padding: 12px 16px 0 16px;
    justify-content: space-between;
}

.helpModal-whatsapp{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    background: #33CC66;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0350996);
    border-radius: 5px;
    border:none;
    outline: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px ;
    width:48%;
    color: #FFFFFF;

}

.helpModal-whatsapp img{
    margin-right:8px
}



@media(max-width:360px) and (min-width:326px){
    .helpModal-whatsapp{
        font-size: 15px;
    
    }

}


@media(max-width:325px){
    .helpModal-whatsapp{
        font-size: 12px;
    }

    
    
}